import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useLayer, useHover, Arrow } from 'react-laag';

import { PROFILES, getProfile } from 'core/config/constants';
import { useModalState } from 'core/hooks';
import { NavigationHeader, Button } from 'core/components';

import { SelectProfileProps } from './types';

function CSelectProfile(
  props: SelectProfileProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const selectModal = useModalState();
  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen: selectModal.isShowing,
  });
  const {
    gender = 'male',
    value = gender === 'male' ? 23 : 0,
    onChange,
    onSubmit,
  } = props;
  const [_value, _setValue] = useState(value);

  const handleClickProfile = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const selected = event.currentTarget.value;
      _setValue(+selected);
      onChange && onChange(+selected);
    },
    [],
  );

  const handleClickBack = () => {
    _setValue(value);
    selectModal.close();
  };

  const handleClickConfirm = useCallback(() => {
    if (value !== _value) {
      onSubmit && onSubmit(_value);
    }
    selectModal.close();
  }, [_value]);

  return (
    <div className="select-profile" ref={ref}>
      <button
        type="button"
        className="btn-profile"
        {...triggerProps}
        onClick={selectModal.open}
      >
        {gender && <img src={getProfile(gender, value)} alt="" />}
      </button>

      {/* 셀렉트 목록 */}
      {selectModal.isShowing &&
        renderLayer(
          <div className="expand-select-profile" {...layerProps}>
            <NavigationHeader
              back
              title="프로필 선택"
              callback={handleClickBack}
            />
            <ul className="list-profile">
              {PROFILES[gender].map((item) => {
                return (
                  <li key={`${gender}-${item.value}`}>
                    {item.label && (
                      <button
                        type="button"
                        value={item.value}
                        onClick={handleClickProfile}
                        className="profile-item"
                        data-selected={item.value === _value}
                      >
                        <img src={item.label} alt="" />
                      </button>
                    )}
                  </li>
                );
              })}
            </ul>
            <div className="navigation-submit-actions">
              <Button
                type="button"
                intent="confirm"
                onClick={handleClickConfirm}
              >
                확인
              </Button>
            </div>
          </div>,
        )}
    </div>
  );
}

const SelectProfileRef = forwardRef(CSelectProfile);
export const SelectProfile = React.memo(SelectProfileRef);
