import firebase from 'firebase/compat/app';
import {
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  signInWithCredential,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  signOut,
} from 'firebase/auth';

import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import config from './config';

const firebaseApp = firebase.initializeApp(config.firebase);

// Add or Remove authentification methods here.
export const Providers = {
  google: new GoogleAuthProvider(),
  apple: new OAuthProvider('apple.com'),
  facebook: new FacebookAuthProvider(),
};

export const authService = getAuth(firebaseApp);
export const dbService = getFirestore(firebaseApp);
export const analytics = getAnalytics(firebaseApp);

authService.onAuthStateChanged(function (user) {
  if (user) {
    // console.log('onAuthStateChanged : ', user);
    // User is signed in.
  } else {
    // No user is signed in.
    console.log('onAuthStateChanged user null');
  }
});

export const SignInWithSocialMedia = (provider: firebase.auth.AuthProvider) =>
  new Promise<firebase.auth.UserCredential>((resolve, reject) => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });

export const SignInWithGoogle = async () => {
  console.log('signInWidthRedirect : ', authService);
  if (process.env.PUBLIC_ENV === 'local') {
    const res = await signInWithRedirect(authService, Providers.google);
  } else {
    const res = await signInWithPopup(authService, Providers.google);
  }
};

export const SignInWithFacebook = async () => {
  const res = await signInWithPopup(authService, Providers.facebook);
};

export const logout = () => {
  signOut(authService);
};

export default firebaseApp;
