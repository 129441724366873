import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import { ButtonProps } from './types';

function CButton(props: ButtonProps, ref: any) {
  const { href, intent, block, disabled, className, children, ...others } =
    props;
  return (
    <>
      {href ? (
        <Link
          to={href}
          data-block={block}
          data-intent={intent}
          className={`ui-control ui-buttons ${className}`}
          data-disabled={disabled}
          ref={ref}
          {...others}
        >
          <span className="txt">{children}</span>
        </Link>
      ) : (
        <button
          type="button"
          data-block={block}
          data-intent={intent}
          className={`ui-control ui-buttons ${className}`}
          data-disabled={disabled}
          ref={ref}
          {...others}
        >
          <span className="txt">{children}</span>
        </button>
      )}
    </>
  );
}

const ButtonRef = forwardRef(CButton);
export const Button = React.memo(ButtonRef);
