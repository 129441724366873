import React, { forwardRef, useEffect, useState } from 'react';
import Select, { components } from 'react-select';

import { SelectControlProps } from './types';
import { getIndianName } from 'core/config';

function CSelectControl(props: SelectControlProps, ref: any) {
  const {
    open = false,
    disabled = false,
    name,
    placeholder = '선택',
    items = [],
    age = 0,
    className = '',
    onBlur,
    onChange,
    ...others
  } = props;
  const [selectedValue, setSelectedValue] = useState<string[]>(
    props.value || [],
  );
  const [values, setValues] = useState<any[]>([]);

  useEffect(() => {
    const _values = selectedValue.map((_selectedValue) => {
      return items.find((_item) => _item.value === _selectedValue);
    });
    setValues(_values);
  }, [selectedValue]);

  // 값 변경
  const handleChange = (newValue: any) => {
    let _values: string[] = [];
    if (newValue) {
      let paramValue = newValue as any[];
      if (!Array.isArray(paramValue)) {
        paramValue = [newValue];
      }

      _values = paramValue.map((_value) => _value.value);
    }

    setSelectedValue(_values);
    onChange && onChange(_values);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(event);
  };

  // Value
  const ValueContainer = (_props: any) => {
    const { children, isMulti, getValue, hasValue, selectProps } = _props;
    const values = getValue();

    let label = '';
    if (hasValue) {
      label =
        selectProps.instanceId === 'indian'
          ? getIndianName(age, values[0].value)
          : values[0].label;
    }

    if (!hasValue) {
      return (
        <components.ValueContainer {..._props}>
          {children}
        </components.ValueContainer>
      );
    }
    return (
      <components.ValueContainer {..._props}>
        {selectProps.inputValue.length === 0 && (
          <>
            {isMulti ? (
              <span className={`ui__multi-value`}>{label}</span>
            ) : (
              <span className={`hc__single-value`}>{label}</span>
            )}
          </>
        )}
        {children[children.length - 1]}
      </components.ValueContainer>
    );
  };

  return (
    <Select
      id={name}
      instanceId={name}
      options={items}
      value={values}
      isSearchable={false}
      placeholder={placeholder}
      hideSelectedOptions={false}
      defaultMenuIsOpen={open}
      openMenuOnFocus={false}
      isDisabled={disabled}
      closeMenuOnSelect={true}
      classNamePrefix={'hc'}
      components={{ ValueContainer }}
      className={`ui-control ui-select-control ${className}`}
      noOptionsMessage={() => {
        return '옵션이 없습니다.';
      }}
      menuPosition="fixed"
      onChange={handleChange}
      onBlur={handleBlur}
      ref={ref}
    />
  );
}

const SelectControlRef = forwardRef(CSelectControl);
export const SelectControl = React.memo(SelectControlRef);
