import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { useCountUp } from 'use-count-up';

export type GameTimerProps = {
  state: string;
  step: number;
  onExpired: (prevStep: number) => void;
};

const MAX_SEC = 7;

function GameTimer(props: GameTimerProps) {
  const { step = 0, state = 'none', onExpired } = props;
  const expired = useRef(0);
  const [remain, setRemain] = useState(0);

  useEffect(() => {
    expired.current = 0;
    setRemain(0);
    reset();
  }, [step]);

  useInterval(
    () => {
      updateTimer();
    },
    state === 'ready' && expired.current <= MAX_SEC ? 50 : null,
  );

  const updateTimer = () => {
    // console.log('updateTimer : ', expired.current);
    expired.current += 0.05;
    setRemain(expired.current);
    if (expired.current >= MAX_SEC) {
      onExpired && onExpired(step - 1);
    }
  };

  const { value, reset } = useCountUp({
    isCounting: true,
    start: 2 ** (step - 1),
    end: 2 ** step,
    duration: 1,
    easing: 'easeOutCubic',
    thousandsSeparator: ',',
  });

  return (
    <div className="game-timer-container" data-status={state}>
      <progress
        id="game-timer"
        className="game-timer"
        max={MAX_SEC}
        value={remain}
      ></progress>
      <label htmlFor="game-timer" className="count">
        1/{value}
      </label>
    </div>
  );
}

export default GameTimer;
