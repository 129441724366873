import React from 'react';

import LayoutHeader from './LayoutHeader';
import LayoutFooter from './LayoutFooter';

type DefaultLayoutProps = {
  children: React.ReactNode;
};

function DefaultLayout(props: DefaultLayoutProps) {
  return (
    <div className="layout-wrap">
      {/* <LayoutHeader /> */}
      {props.children}
      {/* <LayoutFooter /> */}
    </div>
  );
}

export default DefaultLayout;
