import React, { ForwardedRef, forwardRef } from 'react';

import { CirclesProps } from './types';

function CCircles(props: CirclesProps, ref: ForwardedRef<HTMLDivElement>) {
  const { direction = 'none' } = props;

  return (
    <div className="circles" ref={ref} data-direction={direction}>
      <span className="arc"></span>
      <span className="arc arc-03"></span>
      <span className="arc arc-02"></span>
      <span className="arc arc-01"></span>
    </div>
  );
}

const CirclesRef = forwardRef(CCircles);
export const Circles = React.memo(CirclesRef);
