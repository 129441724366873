import { dbService } from 'core/config/firebase';
import {
  query,
  collection,
  where,
  doc,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';

import { IMatchOptions, ICoin, IGame, GameStateTypes, IUser } from 'lib/model';

// 닉네임 중복 체크
export const apiExistNickname = async (nickname: string) => {
  const queryUsers = query(
    collection(dbService, 'users'),
    where('nickname', '==', nickname),
  );

  const docsUsers = await getDocs(queryUsers);
  return docsUsers.docs.length > 0;
};

// 사용자 존재 여부
export const apiExistUsers = async (email: string) => {
  const queryUsers = query(
    collection(dbService, 'users'),
    where('email', '==', email),
  );

  const docsUsers = await getDocs(queryUsers);
  return docsUsers.docs.length > 0;
};

// 사용자 추가
export const apiAddUser = async (data: any, user: any) => {
  try {
    await setDoc(doc(dbService, 'users', user.uid), {
      ...data,
      email: user.email,
      uid: user.uid,
      authProvider: user.providerData[0].providerId,
      age: +data.age,
      indian: +data.indian,
      region: +data.region,
      profile: data.gender === 'male' ? 23 : 0,
      paid: false,
      coin: {
        remain: 5,
        lastUpdated: Date.now(),
      },
      matchOptions: {
        sameSex: false,
      },
      history: [],
      game: {
        currentUid: '',
        state: 'in-game',
      },
      chat: {
        uid: '',
        lastUpdated: Date.now(),
      },
    });
    return {
      success: true,
      message: '',
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error,
    };
  }
};

// 사용자 정보
export const apiGetUser = async (uid: string) => {
  const docRef = doc(dbService, `users/${uid}`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return false;
  }
};

// 사용자 정보
export const apiSetUser = async (user: IUser) => {
  const docRef = doc(dbService, `users/${user.uid}`);
  return await setDoc(docRef, user);
};

// 사용자 profile 정보 업데이트
export const apiUpdateProfile = async (uid: string, profile: number) => {
  try {
    const docRef = doc(dbService, 'users', uid);
    const result = await updateDoc(docRef, {
      profile: profile,
    });
    return {
      success: true,
      message: '프로필 이미지가 업데이트 되었습나다.',
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error,
    };
  }
};

// 사용자 searchOptions 정보 업데이트
export const apiUpdateSearchOptions = async (
  uid: string,
  options: IMatchOptions,
) => {
  try {
    const docRef = doc(dbService, 'users', uid);
    const result = await updateDoc(docRef, {
      matchOptions: options,
    });
    return {
      success: true,
      message: '검색 조건이 업데이트 되었습나다.',
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error,
    };
  }
};

// 사용자 remain core 정보 업데이트
// export const apiAddRemainCoin = async (uid: string) => {
//   try {
//     const docRef = doc(dbService, 'users', uid);
//     const docSnap = await getDoc(docRef);
//     if (docSnap.exists()) {
//       const data = docSnap.data();
//       const result = await updateDoc(docRef, {
//         coin: data.coin.remain + 1,
//       });
//     } else {
//       return false;
//     }
//   } catch (error) {
//     console.log(error);
//     return {
//       success: false,
//       message: error,
//     };
//   }
// };

export const apiUpdateRemainCoin = async (uid: string, remainCoin: ICoin) => {
  try {
    const docRef = doc(dbService, 'users', uid);
    const result = await updateDoc(docRef, {
      coin: remainCoin,
    });
    return {
      success: true,
      message: '코인 정보가 업데이트 되었습나다.',
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error,
    };
  }
};

// 사용자 game state 정보 업데이트
export const apiUpdateGameState = async (gameState: {
  uid: string;
  state: GameStateTypes;
  gameUid?: string;
}) => {
  try {
    console.log('[apiUpdateGameState] : ', gameState);
    const docRef = doc(dbService, 'users', gameState.uid);
    await updateDoc(docRef, {
      game: {
        currentUid: gameState.gameUid,
        state: gameState.state,
      },
    })
      .then((docRef) => {
        return {
          success: true,
          message: 'GameState가 업데이트 되었습나다.',
        };
      })
      .catch((error) => {
        console.log(error);
        return {
          success: false,
          message: error,
        };
      });

    return {
      success: true,
      message: 'GameState가 업데이트 되었습나다.',
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error,
    };
  }
};

//
export const apiAddHistory = async (userUid: string, game: IGame) => {
  try {
    console.log('apiAddHistory : ', userUid, game.uid);
    const userRef = doc(dbService, 'users', userUid);
    const userSnap = await getDoc(userRef);

    let _history = [];
    if (userSnap.exists()) {
      const user = userSnap.data();
      const historyData = {
        createdAt: game.createdAt,
        uid: game.uid,
      };
      _history = [...user.history, historyData];
      const result = await updateDoc(userRef, {
        ...user,
        history: _history,
      });

      return {
        success: true,
        message: '유저 히스토리 추가 성공',
        data: _history,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error,
    };
  }
};

// 사용자 채팅 정보 업데이트
export const apiUpdateChatState = async (chatState: {
  uid: string;
  chatUid?: string;
}) => {
  try {
    console.log('apiUpdateChatState : ', chatState);
    const docRef = doc(dbService, 'users', chatState.uid);
    const result = await updateDoc(docRef, {
      chat: {
        uid: chatState.chatUid,
        lastUpdated: Date.now(),
      },
    });

    return {
      success: true,
      message: 'ChatState가 업데이트 되었습나다.',
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error,
    };
  }
};
