import firebase from 'firebase/compat/app';
import firebaseApp, {
  authService,
  dbService,
  SignInWithGoogle,
} from 'core/config/firebase';
import {
  query,
  collection,
  where,
  doc,
  deleteDoc,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';

import { IWaitingRoom, IUser } from 'lib/model';

export const apiAddNewRoom = async () => {
  const newRoomRef = doc(collection(dbService, 'waitings'));
  const newRoom = {
    uid: newRoomRef.id,
    counts: 0,
    users: [],
  };
  await setDoc(newRoomRef, newRoom);
  return newRoom;
};

export const apiGetMatchingRoom = async (roomUid: string) => {
  const docRef = doc(dbService, `waitings/${roomUid}`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return false;
  }
};

export const apiAddUserInRoom = async (roomUid: string, uid: string) => {
  try {
    await setDoc(doc(dbService, `waitings/${roomUid}/users`, uid), {});
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const apiRemoveUserInRoom = async (roomUid: string, userUid: string) => {
  console.log('removeUserInRoom : ', roomUid, userUid);
  const userInRoomRef = doc(dbService, `waitings/${roomUid}/users`, userUid);
  await deleteDoc(userInRoomRef);
};

export const apiGetMatchingUser = async (roomUid: string, userUid: string) => {
  try {
    console.log('[apiGetMatchingUser] : ', roomUid, userUid);
    const docRef = doc(dbService, `waitings/${roomUid}/users`, userUid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log('[apiGetMatchingUser] : 내가 존재함');
      return false;
    } else {
      console.log('[apiGetMatchingUser] : 내가 존재하지 않음');
      const q = await query(collection(dbService, `waitings/${roomUid}/users`));
      const usersRef = await getDocs(q);
      const users = usersRef.docs.map((doc) => doc.id);
      const filteredUsers = users.filter((user) => user !== userUid);
      const shuffle = filteredUsers.sort(() => Math.random() - 0.5);
      return shuffle[0] ? shuffle[0] : false;
    }
  } catch (error) {
    console.log(error);
  }
};
