import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CountUp } from 'use-count-up';

import { IUser, IChat, IWaitingRoom } from 'lib/model';
import { useUser, useWaitings, useChat } from 'lib/hooks';

import { useModalState } from 'core/hooks';
import {
  Alert,
  Button,
  Circles,
  UserCard,
  NavigationHeader,
} from 'core/components';

const getChatActivated = (_chat: IChat) => {
  if (_chat) {
    return _chat.activated;
  }
  return false;
};

function MainContainer() {
  const navigate = useNavigate();
  const { userInfo, loading, resetUserGameState } = useUser();
  const waitings = useWaitings({
    user: userInfo as IUser,
  });
  const chat = useChat({
    chatUid: userInfo?.chat?.uid,
  });
  const [unread, setUnread] = useState<boolean>(false);

  const startAlert = useModalState({});

  useEffect(() => {
    if (!loading && userInfo !== undefined) {
      if (userInfo) {
        if (userInfo.game.state !== 'none') {
          console.log('메인화면에서 게임상태를 초기화');
          resetUserGameState(userInfo.uid);
        }
      } else {
        toast('프로필 정보를 입력해주세요.');
        navigate('/auth/signup');
      }
    }
  }, [loading, userInfo, waitings]);

  // 채팅 시작 클릭
  const handleClickChatting = useCallback(() => {
    if (userInfo?.chat) {
      if (chat.chatInfo?.activated) {
        navigate('/chat', {
          state: {
            id: chat.chatInfo?.uid,
          },
        });
      } else {
        toast(
          <>
            대화가 종료되었습니다.
            <br />
            인연을 찾으면 채팅이 가능합니다.
          </>,
        );
      }
    } else {
      toast('인연을 찾으면 채팅이 가능합니다.');
    }
  }, [userInfo?.chat, chat]);

  // 게임 버튼 클릭
  const handleClickStart = () => {
    if (userInfo && userInfo.coin.remain <= 0) {
      toast('코인을 충전해주세요');
    } else {
      if (chat.chatInfo?.activated) {
        startAlert.open();
      } else {
        handleGameStart();
      }
    }
  };

  // 대화 비활성화
  const handleInActivate = () => {
    if (chat && userInfo?.chat) {
      chat.putInActivate(userInfo?.chat?.uid);
    }
  };

  // 게임 시작
  const handleGameStart = () => {
    if (userInfo) {
      navigate('/waiting', {
        replace: true,
        state: {
          userUid: userInfo.uid,
        },
      });
    }
  };

  useEffect(() => {
    if (userInfo && chat.messages) {
      const lastMessage = chat.messages[chat.messages.length - 1];
      if (lastMessage) {
        const _date = lastMessage.createdAt as any;
        const _last = _date.seconds * 1000 + _date.nanoseconds / 1000000;
        const _userLast = userInfo.chat?.lastUpdated as number;
        setUnread(_last > _userLast);
      }
    }
  }, [userInfo, chat.messages]);

  return (
    <main className="layout-contents-container page-main">
      <NavigationHeader setting counter />

      <div className="contents-container">
        {userInfo && <UserCard data={userInfo} />}

        <div className="actions">
          <button
            type="button"
            data-disabled={!getChatActivated(chat.chatInfo as IChat)}
            data-unread={unread}
            className="btn-chatting"
            onClick={handleClickChatting}
          >
            대화 시작
          </button>
        </div>
      </div>

      <Circles />

      <div className="navigation-submit-actions">
        <Button
          type="submit"
          intent="confirm"
          onClick={handleClickStart}
          disabled={userInfo?.coin.remain === 0}
        >
          시작하기
        </Button>
      </div>

      {/* Alert */}
      <Alert
        open={startAlert.isShowing}
        buttons={
          <>
            <button type="button" onClick={startAlert.close}>
              취소
            </button>
            <button
              type="button"
              className="confirm"
              onClick={() => {
                startAlert.close();
                handleInActivate();
                handleGameStart();
              }}
            >
              확인
            </button>
          </>
        }
      >
        <p className="message">
          새로운 인연을 찾으려면 기존 채팅방이 삭제됩니다.
          <br />
          동의하십니까?
        </p>
      </Alert>
    </main>
  );
}

export default MainContainer;
