import { useState } from 'react';

type useModalStateProps = {
  showing?: boolean;
};

//모달 제어를 쉽게 하기 위한 Hook
export const useModalState = (props: useModalStateProps = {}) => {
  const { showing = false } = props;
  const [isShowing, setIsShowing] = useState<boolean>(showing);

  const toggle = () => {
    setIsShowing(!isShowing);
  };

  const open = () => {
    setIsShowing(true);
  };

  const close = () => {
    setIsShowing(false);
  };

  const setShowing = (value: boolean) => {
    setIsShowing(value);
  };

  return {
    isShowing,
    toggle,
    close,
    open,
    setShowing,
  };
};
