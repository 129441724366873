import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import { useRecoilState } from 'recoil';
import { userAtomState } from 'core/states';
import { dbService } from 'core/config/firebase';
import { doc } from 'firebase/firestore';

import { ICoin, IUser, IGame, GameStateTypes } from 'lib/model';
import {
  apiExistUsers,
  apiUpdateProfile,
  apiUpdateSearchOptions,
  apiUpdateRemainCoin,
  apiUpdateGameState,
  apiUpdateChatState,
  apiAddHistory,
  apiSetUser,
} from 'lib/api';

type useUserProps = {
  uid?: string;
};

export const useUser = (props: useUserProps = {}) => {
  const { uid } = props;
  const navigate = useNavigate();
  const [stateUser] = useRecoilState(userAtomState);
  const [userInfo, setUserInfo] = useState<IUser | undefined | null>(undefined);

  const [value, loading, error] = useDocumentData(
    stateUser.userID !== '' ? doc(dbService, 'users', stateUser.userID) : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  useEffect(() => {
    if (!loading && !error && value) {
      console.log('[useUser] : ', value.game);
      console.log(userInfo?.game);
      if (value) {
        setUserInfo(value as IUser);
        // setUserInfo({
        //   email: _user.email,
        //   uid: _user.uid,
        //   authProvider: _user.authProvider,
        //   age: _user.age,
        //   indian: _user.indian,
        //   region: +_user.region,
        //   profile: _user.profile,
        //   paid: _user.paid,
        //   gender: _user.gender,
        //   nickname: _user.nickname,
        //   coin: _user.coin,
        //   matchOptions: _user.matchOptions,
        //   game: _user.game,
        //   chat: _user.chat,
        //   history: _user.history,
        // });
      }
    }
  }, [loading, error, value]);

  const updateUserProfile = async (user: IUser) => {
    const result = await apiUpdateProfile(stateUser.userID, user.profile);
    if (result.success) {
      toast(result.message as string);
    }
  };

  const updateUserSearchOptions = async (user: IUser) => {
    const result = await apiUpdateSearchOptions(
      stateUser.userID,
      user.matchOptions,
    );
    if (result.success) {
      toast(result.message as string);
    }
  };

  const updateUser = async (user: IUser) => {
    console.log('[updateUserGameState] : ');
    const result = await apiSetUser(user);

    return result;
    // if (result.success) {
    //   toast(result.message as string);
    // }
  };

  const updateUserGameState = async (gameState: {
    uid: string;
    state: GameStateTypes;
    gameUid?: string;
  }) => {
    console.log('[updateUserGameState] : ', gameState.state);
    const result = await apiUpdateGameState(gameState);

    if (result.success) {
      // toast(result.message as string);
    }
  };

  const resetUserGameState = async (uid: string) => {
    console.log('[resetUserGameState]');
    updateUserGameState({
      uid: uid,
      state: 'none',
      gameUid: '',
    });
  };

  const exist = async (email: string) => {
    const exist = await apiExistUsers(email);
    if (!exist) {
      toast('프로필 정보를 입력해주세요.');
      navigate('/auth/signup');
    }
  };

  const setRemainCoin = async (coin: ICoin) => {
    if (userInfo) {
      const result = await apiUpdateRemainCoin(userInfo.uid, coin);
    }
  };

  const addHistory = async (userUid: string, game: IGame) => {
    console.log('[addHistory]');
    const result = await apiAddHistory(userUid, game);
  };

  const updateChatLastInfo = async (chatUid: string, userUid: string) => {
    console.log('[updateChatLastInfo] : ', chatUid, userUid);
    const result = await apiUpdateChatState({
      uid: userUid,
      chatUid: chatUid,
    });
  };

  return {
    userInfo,
    loading,
    updateUserProfile,
    updateUserSearchOptions,
    updateUserGameState,
    updateUser,
    resetUserGameState,
    updateChatLastInfo,
    exist,
    setRemainCoin,
    addHistory,
  };
};
