import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';

import { NavigationHeader, Button } from 'core/components';
import { SelectAnswer } from 'core/components/@forms';

import { useWaitings, useGame, useUser, useChat } from 'lib/hooks';

import GameTimer from './gameTimer';

export function GameContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { id: string };

  !state && navigate('/main');

  const { userInfo, addHistory, setRemainCoin } = useUser();
  const { roomUid, removeUserInRoom } = useWaitings();
  const { gameInfo, putAnswer, confirmAnswer, getOpponentUid } = useGame({
    gameUid: state?.id,
  });
  const { createChat } = useChat();
  const [_step, _setStep] = useState(-1);
  const [_keyword, _setKeyword] = useState<string[]>([]);
  const [_expired, _setExpired] = useState(false);

  useEffect(() => {
    if (userInfo && roomUid) {
      console.log('나자신 대화방에서 빼내기 : ');
      removeUserInRoom(roomUid, userInfo?.uid);
    }
  }, [userInfo, roomUid]);

  // 게임 시작
  useEffect(() => {
    if (gameInfo && gameInfo?.step < 20) {
      console.log('==== 게임시작 ====');
      const keywords = gameInfo?.keywords;
      const shuffleKeyword = _.shuffle(keywords[gameInfo?.step].keywords);
      _setKeyword(shuffleKeyword);
      _setExpired(false);
    }
  }, [gameInfo?.step]);

  // 게임 결과
  useEffect(() => {
    if (gameInfo?.correct !== undefined) {
      const opponent = userInfo && getOpponentUid(userInfo?.uid);
      if (gameInfo?.correct && gameInfo?.step >= 20) {
        if (opponent && userInfo && gameInfo?.host?.uid === userInfo?.uid) {
          createChat([userInfo?.uid, opponent]);
        }
        navigate('/game-over', {
          replace: true,
          state: {
            opponentUID: opponent,
            success: true,
            game: gameInfo.uid,
            step: gameInfo?.step,
          },
        });
      } else if (!gameInfo?.correct && gameInfo?.step < 20) {
        navigate('/game-over', {
          replace: true,
          state: {
            opponentUID: opponent,
            success: false,
            game: gameInfo.uid,
            step: gameInfo?.step,
          },
        });
      }
    }
  }, [gameInfo?.correct, gameInfo?.step]);

  // 넥스트 스텝
  useEffect(() => {
    if (gameInfo) {
      if (gameInfo.step === _step + 1) {
        _setStep(gameInfo.step);
        _setExpired(false);
      }
    }
  }, [gameInfo?.step, _step]);

  // user : 게임 히스토리 추가
  useEffect(() => {
    if (gameInfo && userInfo) {
      if (_step === -1 && gameInfo.step === _step + 1) {
        console.log('게임 히스토리 추가');
        addHistory(userInfo?.uid, gameInfo);
      }
    }
  }, [gameInfo, userInfo, _step]);

  // 답변 선택
  const handleChangeAnswer = (value: string) => {
    if (userInfo?.uid && gameInfo?.uid) {
      putAnswer(gameInfo?.uid, userInfo?.uid, _step, value);
    }
  };

  // 시간 초과
  const handleExpired = (prevStep: number) => {
    console.log('[expired] : ', prevStep);
    _setExpired(true);
    if (userInfo?.uid && gameInfo?.uid) {
      setTimeout(() => {
        confirmAnswer(gameInfo?.uid, userInfo?.uid, prevStep).then((result) => {
          console.log('[confirmAnswer] : ', result);
        });
      }, 1100);
    }
  };

  return (
    <main className="layout-contents-container page-game">
      <NavigationHeader
        close
        step={_step + 1}
        callback={() => {
          navigate('/main');
        }}
      />
      <div className="game-container">
        {_step >= 0 && (
          <SelectAnswer
            options={[
              { label: _keyword[0], value: _keyword[0] },
              { label: _keyword[1], value: _keyword[1] },
            ]}
            onChange={handleChangeAnswer}
          />
        )}
        <GameTimer
          state={!_expired && gameInfo?.step === _step ? 'ready' : 'none'}
          step={_step + 1}
          onExpired={handleExpired}
        />
      </div>
    </main>
  );
}
