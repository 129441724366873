// 인디언
export const INDIAN_NAME = [
  [
    '시끄러운',
    '푸른',
    '어두운',
    '조용한',
    '웅크린',
    '백색',
    '지혜로운',
    '용감한',
    '날카로운',
    '욕심 많은',
  ],
  [
    '늑대',
    '태양',
    '양',
    '매',
    '황소',
    '불꽃',
    '나무',
    '달빛',
    '말',
    '돼지',
    '하늘',
    '바람',
  ],
];

export const getIndianName = (age: number, month: number) => {
  const thisYear = new Date();
  const birthYear = (thisYear.getFullYear() - age).toString().split('');
  return `${INDIAN_NAME[0][+birthYear[birthYear.length - 1]]} ${
    INDIAN_NAME[1][month]
  }`;
};

// 프로파일
export const PROFILES = {
  male: [
    {
      label: '/assets/images/male/male_01@2x.png',
      value: 0,
    },
    {
      label: '/assets/images/male/male_02@2x.png',
      value: 1,
    },
    {
      label: '/assets/images/male/male_03@2x.png',
      value: 2,
    },
    {
      label: '/assets/images/male/male_04@2x.png',
      value: 3,
    },
    {
      label: '/assets/images/male/male_05@2x.png',
      value: 4,
    },
    {
      label: '/assets/images/male/male_06@2x.png',
      value: 5,
    },
    {
      label: '/assets/images/male/male_07@2x.png',
      value: 6,
    },
    {
      label: '/assets/images/male/male_08@2x.png',
      value: 7,
    },
    {
      label: '/assets/images/male/male_09@2x.png',
      value: 8,
    },
    {
      label: '/assets/images/male/male_10@2x.png',
      value: 9,
    },
    {
      label: '/assets/images/male/male_11@2x.png',
      value: 10,
    },
    {
      label: '/assets/images/male/male_12@2x.png',
      value: 11,
    },
    {
      label: '/assets/images/male/male_13@2x.png',
      value: 12,
    },
    {
      label: '/assets/images/male/male_14@2x.png',
      value: 13,
    },
    {
      label: '/assets/images/male/male_15@2x.png',
      value: 14,
    },
    {
      label: '/assets/images/male/male_16@2x.png',
      value: 15,
    },
    {
      label: '/assets/images/male/male_17@2x.png',
      value: 16,
    },
    {
      label: '/assets/images/male/male_18@2x.png',
      value: 17,
    },
    {
      label: '/assets/images/male/male_19@2x.png',
      value: 18,
    },
    {
      label: '/assets/images/male/male_20@2x.png',
      value: 19,
    },
    {
      label: '/assets/images/male/male_21@2x.png',
      value: 20,
    },
    {
      label: '/assets/images/male/male_22@2x.png',
      value: 21,
    },
    {
      label: '/assets/images/male/male_23@2x.png',
      value: 22,
    },
    {
      label: '/assets/images/male/male_24@2x.png',
      value: 23,
    },
    {
      label: '/assets/images/male/male_25@2x.png',
      value: 24,
    },
    {
      label: '/assets/images/male/male_26@2x.png',
      value: 25,
    },
    {
      label: '/assets/images/male/male_27@2x.png',
      value: 26,
    },
    {
      label: '/assets/images/male/male_28@2x.png',
      value: 27,
    },
    {
      label: '/assets/images/male/male_29@2x.png',
      value: 28,
    },
    {
      label: '/assets/images/male/male_30@2x.png',
      value: 29,
    },
    {
      label: '/assets/images/male/male_31@2x.png',
      value: 30,
    },
    {
      label: '/assets/images/male/male_32@2x.png',
      value: 31,
    },
    {
      label: '/assets/images/male/male_33@2x.png',
      value: 32,
    },
    {
      label: '/assets/images/male/male_34@2x.png',
      value: 33,
    },
    {
      label: '/assets/images/male/male_35@2x.png',
      value: 34,
    },
    {
      label: '/assets/images/male/male_36@2x.png',
      value: 35,
    },
  ],
  female: [
    {
      label: '/assets/images/female/female_01@2x.png',
      value: 0,
    },
    {
      label: '/assets/images/female/female_02@2x.png',
      value: 1,
    },
    {
      label: '/assets/images/female/female_03@2x.png',
      value: 2,
    },
    {
      label: '/assets/images/female/female_04@2x.png',
      value: 3,
    },
    {
      label: '/assets/images/female/female_05@2x.png',
      value: 4,
    },
    {
      label: '/assets/images/female/female_06@2x.png',
      value: 5,
    },
    {
      label: '/assets/images/female/female_07@2x.png',
      value: 6,
    },
    {
      label: '/assets/images/female/female_08@2x.png',
      value: 7,
    },
    {
      label: '/assets/images/female/female_09@2x.png',
      value: 8,
    },
    {
      label: '/assets/images/female/female_10@2x.png',
      value: 9,
    },
    {
      label: '/assets/images/female/female_11@2x.png',
      value: 10,
    },
    {
      label: '/assets/images/female/female_12@2x.png',
      value: 11,
    },
    {
      label: '/assets/images/female/female_13@2x.png',
      value: 12,
    },
    {
      label: '/assets/images/female/female_14@2x.png',
      value: 13,
    },
    {
      label: '/assets/images/female/female_15@2x.png',
      value: 14,
    },
    {
      label: '/assets/images/female/female_16@2x.png',
      value: 15,
    },
    {
      label: '/assets/images/female/female_17@2x.png',
      value: 16,
    },
    {
      label: '/assets/images/female/female_18@2x.png',
      value: 17,
    },
    {
      label: '/assets/images/female/female_19@2x.png',
      value: 18,
    },
    {
      label: '/assets/images/female/female_20@2x.png',
      value: 19,
    },
    {
      label: '/assets/images/female/female_21@2x.png',
      value: 20,
    },
    {
      label: '/assets/images/female/female_22@2x.png',
      value: 21,
    },
    {
      label: '/assets/images/female/female_23@2x.png',
      value: 22,
    },
    {
      label: '/assets/images/female/female_24@2x.png',
      value: 23,
    },
    {
      label: '/assets/images/female/female_25@2x.png',
      value: 24,
    },
    {
      label: '/assets/images/female/female_26@2x.png',
      value: 25,
    },
    {
      label: '/assets/images/female/female_27@2x.png',
      value: 26,
    },
    {
      label: '/assets/images/female/female_28@2x.png',
      value: 27,
    },
    {
      label: '/assets/images/female/female_29@2x.png',
      value: 28,
    },
    {
      label: '/assets/images/female/female_30@2x.png',
      value: 29,
    },
    {
      label: '/assets/images/female/female_31@2x.png',
      value: 30,
    },
    {
      label: '/assets/images/female/female_32@2x.png',
      value: 31,
    },
    {
      label: '/assets/images/female/female_33@2x.png',
      value: 32,
    },
    {
      label: '/assets/images/female/female_34@2x.png',
      value: 33,
    },
    {
      label: '/assets/images/female/female_35@2x.png',
      value: 34,
    },
    {
      label: '/assets/images/female/female_36@2x.png',
      value: 35,
    },
  ],
};

export const getProfile = (gender: 'male' | 'female', profile: number) => {
  return PROFILES[gender][profile].label;
};

// 지역
export const REGION = [
  {
    label: '서울시',
    value: 0,
  },
  {
    label: '부산시',
    value: 1,
  },
  {
    label: '대구시',
    value: 2,
  },
  {
    label: '인천시',
    value: 3,
  },
  {
    label: '광주시',
    value: 4,
  },
  {
    label: '대전시',
    value: 5,
  },
  {
    label: '울산시',
    value: 6,
  },
  {
    label: '세종시',
    value: 7,
  },
  {
    label: '경기도',
    value: 8,
  },
  {
    label: '강원도',
    value: 9,
  },
  {
    label: '충청북도',
    value: 10,
  },
  {
    label: '충청남도',
    value: 11,
  },
  {
    label: '전라북도',
    value: 12,
  },
  {
    label: '전라남도',
    value: 13,
  },
  {
    label: '경상북도',
    value: 14,
  },
  {
    label: '경상남도',
    value: 15,
  },
  {
    label: '제주도',
    value: 16,
  },
];

export const getRegion = (value: number) => {
  const result = REGION.find((region) => region.value === value);
  return result?.label;
};

// coin
export const REMAIN_MAX = 5;
export const REMAIN_DURATION = 30;
