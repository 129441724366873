import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import { useWaitings, useUser, useChat } from 'lib/hooks';
import { Button, UserCard, NavigationHeader } from 'core/components';

import { GameEndingProps } from './types';

export function GameOverContainer() {
  const location = useLocation();
  const state = location.state as GameEndingProps;
  const step = state.step ?? 0;
  const { roomUid, removeUserInRoom } = useWaitings();

  const navigate = useNavigate();
  const { userInfo, resetUserGameState } = useUser({
    uid: state.opponentUID,
  });
  const { putUserProfile } = useChat();
  const [done, setDone] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDone(true);
    }, 2000);
  }, []);

  useEffect(() => {
    if (done && roomUid && state) {
      // 상대방 정보도 리셋
      console.log('상대방 정보 리셋 : ', state.opponentUID);
      removeUserInRoom(roomUid, state.opponentUID as string);
      resetUserGameState(state.opponentUID as string);
    }
  }, [roomUid, done, state]);

  useEffect(() => {
    if (!state.opponentUID) {
      navigate('/main');
    }
  }, [state.opponentUID]);

  useEffect(() => {
    if (userInfo && userInfo.chat) {
      putUserProfile(userInfo.chat.uid, {
        gender: userInfo.gender,
        profile: userInfo.profile,
        nickname: userInfo.nickname,
        uid: userInfo.uid,
      });
    }
  }, [userInfo]);

  return (
    <main className="layout-contents-container page-game-results">
      <NavigationHeader
        close
        callback={() => {
          navigate('/main');
        }}
      />

      <div className="contents-container">
        {userInfo && done && (
          <AnimatePresence>
            <motion.div
              animate={done ? 'open' : 'closed'}
              initial={{ opacity: 0, y: '-150%' }}
              variants={{
                open: { opacity: 1, y: 0 },
                closed: { opacity: 0, y: '-150%' },
              }}
              className="result-card"
            >
              <UserCard data={userInfo} privacy={!state.success} />
            </motion.div>
          </AnimatePresence>
        )}

        {state.success ? (
          <>
            <div className="bg-congratulation">
              <img src="/assets/svgs/bg_congratulation_01.svg" alt="" />
            </div>

            <h1 className="title">Congratulation!</h1>
            <p className="message">
              백만분의 1, 놀라운 인연과의 채팅창이 열렸습니다!
            </p>
          </>
        ) : (
          <>
            <div className="bg-congratulation">땡!</div>
            <p className="message provability">1/{2 ** step}의 인연입니다.</p>
          </>
        )}
      </div>

      <AnimatePresence>
        {done && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="navigation-submit-actions"
          >
            <Button href="/main" type="button" intent="primary">
              확인
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </main>
  );
}
