import React, { useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { doc, collection } from 'firebase/firestore';

import { dbService } from 'core/config/firebase';

import {
  apiUpdateGameState,
  apiAddUserInRoom,
  apiRemoveUserInRoom,
  apiGetMatchingUser,
} from 'lib/api';
import { IWaitingRoom, IUser } from 'lib/model';

type useWaitingsProps = {
  user?: IUser;
};

export const useWaitings = (props: useWaitingsProps = {}) => {
  const { user } = props;
  const [roomUid, setRoomUid] = useState('all');
  const [usersInRoom, setUsersInRoom] = useState<string[]>([]);

  // useEffect(() => {
  //   if (user?.matchOptions.sameSex) {
  //     setRoomUid(user?.gender);
  //   } else {
  //     setRoomUid('all');
  //   }
  // }, [user]);

  const [value, loading, error] = useCollection(
    roomUid !== '' ? collection(dbService, `waitings/${roomUid}/users`) : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  useEffect(() => {
    if (!loading && !error && value) {
      const users = value.docs.map((doc) => doc.id);
      console.log('useWaitings : ', roomUid, users);
      setUsersInRoom(users);
    }
  }, [loading, error, value]);

  const addInRoom = async (userUid: string, uid: string) => {
    return await apiAddUserInRoom(userUid, uid);
  };

  const matchingUser = async (roomUid: string, userUid: string) => {
    const result = await apiGetMatchingUser(roomUid, userUid).then((value) => {
      console.log('maching id : ', value);
      return value;
    });

    return result;
  };

  const removeUserInRoom = (roomUid: string, uid: string) => {
    console.log('useWaitings - removeUserInRoom');
    apiRemoveUserInRoom(roomUid, uid);
  };

  return { roomUid, usersInRoom, addInRoom, matchingUser, removeUserInRoom };
};
