import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { authService } from 'core/config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

import { IRoute } from './types';

function RouteWithAuth(props: IRoute) {
  const {
    component: Component,
    layout: Layout,
    protected: Protected,
    ...others
  } = props;
  const [user, loading, error] = useAuthState(authService);

  return (
    <>
      {!loading && (
        <>
          {Protected && !user ? (
            <Navigate to="/" />
          ) : (
            <Layout>
              <Component {...others} />
            </Layout>
          )}
        </>
      )}
    </>
  );
}
export default RouteWithAuth;
