import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer, Flip } from 'react-toastify';
import ReactModal from 'react-modal';

import { useAuthState } from 'react-firebase-hooks/auth';

import { useRecoilState } from 'recoil';
import { userAtomState } from 'core/states';

import { IRoute, routes, RouteWithAuth } from 'core/routes';
import { authService } from 'core/config/firebase';

import 'assets/styles/oneinmil.ui.scss';

ReactModal.setAppElement('#root');
function App() {
  const [user, loading] = useAuthState(authService);
  const [stateUser, setStateUser] = useRecoilState(userAtomState);

  useEffect(() => {
    if (!loading && user) {
      setStateUser({
        ...stateUser,
        userID: user.uid,
      });
    }
  }, [loading, user]);

  return (
    <>
      <Routes>
        {routes.map((_item: IRoute, _index: number) => {
          const { component, layout, ...others } = _item;
          return (
            <Route
              key={_index}
              path={_item.path}
              element={
                <RouteWithAuth
                  layout={layout}
                  component={component}
                  {...others}
                />
              }
            />
          );
        })}
      </Routes>
      <ToastContainer
        position="top-center"
        transition={Flip}
        autoClose={3000}
        draggable={false}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnHover
        closeButton={false}
      />
    </>
  );
}

export default App;
