import firebase from 'firebase/compat/app';
import { dbService } from 'core/config/firebase';
import {
  query,
  collection,
  where,
  doc,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import crypto from 'crypto-js';

import { IChatUser, IChat } from 'lib/model';

// 새로운 챗 추가(챗 내부 유저정보는 유저 각자가 넣음)
export const apiAddChat = async (host: string, guest: string) => {
  try {
    console.log('apiAddChat');
    const dbRef = doc(collection(dbService, 'chats'));

    const data = {
      activated: true,
      uid: dbRef.id,
      createdAt: new Date(),
      host: {
        uid: host,
      },
      guest: {
        uid: guest,
      },
    };
    await setDoc(dbRef, data);
    return {
      success: true,
      message: '챗 생성 성공',
      data: data,
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error,
      data: undefined,
    };
  }
};

// 채팅 내부 사용자 정보 입력
export const apiUpdateChatUser = async (chatUid: string, user: IChatUser) => {
  const chatRef = doc(dbService, `chats/${chatUid}`);
  const chatSnap = await getDoc(chatRef);

  if (chatSnap.exists()) {
    const data = chatSnap.data();
    let result = null;
    if (data.host.uid == user.uid) {
      result = await updateDoc(chatRef, {
        host: user,
      });
    } else if (data.guest.uid === user.uid) {
      result = await updateDoc(chatRef, {
        guest: user,
      });
    }

    return {
      success: true,
      message: '채팅 업데이트',
      result: result,
    };
  } else {
    return {
      success: false,
      message: '채팅 업데이트 error',
    };
  }
};

// 챗 activated 변경
export const apiInActivate = async (chatUid: string) => {
  try {
    console.log('apiInActivate : ', chatUid);
    const chatRef = doc(dbService, `chats/${chatUid}`);
    const chatSnap = await getDoc(chatRef);

    if (chatSnap.exists()) {
      const data = chatSnap.data();
      const result = await updateDoc(chatRef, {
        activated: false,
      });
      return {
        success: true,
        message: '채팅 activate 업데이트',
      };
    } else {
      return {
        success: false,
        message: '채팅 activate 업데이트 error',
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error,
      data: undefined,
    };
  }
};

// 메시지 목록 가져오기
export const apiGetMessages = async (chatUid: string) => {
  try {
    console.log('apiGetMessages : ', chatUid);
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error,
      data: undefined,
    };
  }
};

// 메시지 전송
export const apiPutMessage = async (
  chatUid: string,
  userUid: string,
  message: string,
) => {
  try {
    console.log('apiPutMessage : ', chatUid, userUid);
    const chatRef = doc(dbService, `chats/${chatUid}`);
    const messagesRef = collection(chatRef, 'messages');

    addDoc(messagesRef, {
      createdAt: new Date(),
      message: crypto.AES.encrypt(
        message,
        `${process.env.MESSAGE_SECRET_KEY}`,
      ).toString(),
      user: userUid,
    });
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error,
      data: undefined,
    };
  }
};
