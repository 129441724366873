import React, { ForwardedRef, forwardRef } from 'react';

import { UserCardProps } from './types';

import { getProfile, getRegion, getIndianName } from 'core/config/constants';

function CUserCard(props: UserCardProps, ref: ForwardedRef<HTMLElement>) {
  const { data, className = '', privacy = false } = props;
  const { gender, profile, nickname, age, region, indian } = data;

  return (
    <section
      className={`user-card ${className}`}
      data-gender={gender}
      ref={ref}
    >
      <span className="tag"></span>
      <img src={getProfile(gender, profile)} alt={nickname} className="thumb" />
      <h1 className="nickname">{privacy ? '*****' : nickname}</h1>
      <p className="desc">{`${age}세 / ${getRegion(region)} / ${getIndianName(
        age,
        indian,
      )}`}</p>
    </section>
  );
}

const UserCardRef = forwardRef(CUserCard);
export const UserCard = React.memo(UserCardRef);
