import firebase from 'firebase/compat/app';
import { dbService } from 'core/config/firebase';
import {
  query,
  collection,
  where,
  doc,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';

import { apiGetKeywords } from './apiKeywords';
import { IGame, IUserInGame } from 'lib/model';

// 게임 추가
export const apiAddGame = async (users: string[]) => {
  try {
    const gameRef = doc(collection(dbService, 'games'));

    const keywords = await apiGetKeywords();
    const data: IGame = {
      uid: gameRef.id,
      createdAt: new Date(),
      host: {
        uid: users[0],
        answers: [],
      },
      guest: {
        uid: users[1],
        answers: [],
      },
      step: 0,
      correct: true,
      keywords: keywords.data ? keywords.data : [],
    };
    await setDoc(gameRef, data);

    return {
      success: true,
      message: '게임 생성 성공',
      data: data,
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error,
      data: undefined,
    };
  }
};

// 답변 추가
export const apiPutAnswer = async (
  game: string,
  user: string,
  step: number,
  answer: string,
) => {
  try {
    const gameRef = doc(dbService, `games/${game}`);
    const gameSnap = await getDoc(gameRef);

    let _answers = [];
    if (gameSnap.exists()) {
      const data = gameSnap.data();
      const _isHost = data.host.uid === user;
      _answers = _isHost ? [...data.host.answers] : [...data.guest.answers];
      _answers[step] = answer;

      await updateDoc(
        gameRef,
        _isHost
          ? {
              host: {
                ...data.host,
                answers: _answers,
              },
            }
          : {
              guest: {
                ...data.guest,
                answers: _answers,
              },
            },
      );

      return {
        success: true,
        message: `대답이 업데이트 되었습니다. : ${answer}`,
      };
    } else {
      return {
        success: false,
        message: 'error',
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error,
      data: undefined,
    };
  }
};

// 다음 스텝
export const apiNextAnswer = async (
  game: string,
  correct: boolean,
  step: number,
) => {
  try {
    const gameRef = doc(dbService, `games/${game}`);
    const gameSnap = await getDoc(gameRef);

    if (gameSnap.exists()) {
      const data = gameSnap.data();

      const result = await updateDoc(gameRef, {
        ...data,
        correct: correct,
        step: correct ? step + 1 : step,
      });

      return {
        success: true,
        message: '스텝 업데이트',
      };
    } else {
      return {
        success: false,
        message: 'error',
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error,
      data: undefined,
    };
  }
};

// 답변 체크
export const apiConfirmAnswer = async (
  game: string,
  user: string,
  step: number,
) => {
  try {
    const gameRef = doc(dbService, `games/${game}`);
    const gameSnap = await getDoc(gameRef);

    if (gameSnap.exists()) {
      const data = gameSnap.data();

      const answersHost = data.host.answers;
      const answersGuest = data.guest.answers;

      let result = true;
      if (answersHost[step] !== undefined && answersGuest[step] !== undefined) {
        result = answersHost[step] === answersGuest[step];
      } else {
        result = false;
      }

      apiNextAnswer(game, result, step);
      return result;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error,
      data: undefined,
    };
  }
};
