import React, { forwardRef, useRef, useEffect, useState } from 'react';
import { useInterval } from 'react-use';

import { getTime, addMinutes } from 'date-fns';

import { useUser } from 'lib/hooks';
import { RemainCounterProps } from './types';
import { REMAIN_MAX, REMAIN_DURATION } from 'core/config';

function CRemainCounter(props: RemainCounterProps, ref: any) {
  const { userInfo, loading, setRemainCoin } = useUser();
  const [displayTimer, setDisplayTimer] = useState('00:00');
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (!loading && userInfo) {
      if (firstUpdate.current) {
        updateTimer(false);
      }
    }
  }, [loading, userInfo]);

  const updateTimer = (isInterval: boolean) => {
    if (userInfo) {
      const _lastUpdated = userInfo.coin.lastUpdated;
      const _elapsed = Date.now() - userInfo.coin.lastUpdated;
      const _elapsedMin = Math.floor(_elapsed / 60000);
      const _elapsedSec = (_elapsed % 60000) / 1000;

      if (isInterval) {
        const min = REMAIN_DURATION - (_elapsedMin % REMAIN_DURATION) - 1;
        const sec = 60 - +_elapsedSec.toFixed(0);

        if (min === 0 && sec === 0) {
          const _newCoin = {
            remain: userInfo.coin.remain + 1,
            lastUpdated: getTime(
              addMinutes(userInfo.coin.lastUpdated, REMAIN_DURATION),
            ),
          };

          setRemainCoin(_newCoin);
        }

        setDisplayTimer(
          `${min.toString().padStart(2, '0')}:${sec
            .toString()
            .padStart(2, '0')}`,
        );
      } else {
        const addCoin = Math.floor(_elapsedMin / REMAIN_DURATION);
        const _added = addMinutes(_lastUpdated, addCoin * REMAIN_DURATION);

        const _availableCoin = addCoin + userInfo.coin.remain;
        const _newCoin = {
          remain: _availableCoin >= REMAIN_MAX ? REMAIN_MAX : _availableCoin,
          lastUpdated:
            _availableCoin >= REMAIN_MAX ? Date.now() : getTime(_added),
        };
        setRemainCoin(_newCoin);
        firstUpdate.current = false;
      }
    }
  };

  useInterval(
    () => {
      updateTimer(true);
    },
    userInfo && userInfo.coin.remain < REMAIN_MAX ? 1000 : null,
  );

  return (
    <div className="remain-counter">
      <time dateTime="" className="timer">
        {userInfo && userInfo.coin.remain >= REMAIN_MAX ? 'FULL' : displayTimer}
      </time>
      <strong className="remain">{userInfo?.coin.remain}</strong>
    </div>
  );
}

const forwardRemainCounter = forwardRef(CRemainCounter);
// export const RemainCounter = forwardRemainCounter;
export const RemainCounter = React.memo(forwardRemainCounter);
