import firebase from 'firebase/compat/app';
import { dbService } from 'core/config/firebase';
import {
  query,
  collection,
  orderBy,
  limit,
  where,
  doc,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';

import { ICategory, IKeywords } from 'lib/model';

const generateRandom = (total: number) => {
  const max = 20;
  const random: number[] = [];
  for (let i = 0; i < max; i++) {
    const temp: number = Math.floor(Math.random() * (total - 1));
    if (random.indexOf(temp) === -1 && temp !== 0) {
      random.push(temp);
    } else i--;
  }
  return random;
};

// 키워드 가져오기
export const apiGetKeywords = async () => {
  try {
    const keywordsRef = collection(dbService, 'keywords');
    const total = await getDocs(query(keywordsRef));

    const random = generateRandom(total.size);
    const half = Math.ceil(random.length / 2);
    // console.log(
    //   'keyword random index : ',
    //   random,
    //   half,
    //   random.slice(0, half),
    //   random.slice(half),
    // );
    const result: IKeywords[] = [];

    const q1 = query(keywordsRef, where('index', 'in', random.slice(0, half)));
    const docsSnap1 = await getDocs(q1);
    docsSnap1.forEach((doc) => {
      // console.log(doc.id, ' => ', doc.data());
      result.push(doc.data() as IKeywords);
    });

    const q2 = query(keywordsRef, where('index', 'in', random.slice(half)));
    const docsSnap2 = await getDocs(q2);
    docsSnap2.forEach((doc) => {
      // console.log(doc.id, ' => ', doc.data());
      result.push(doc.data() as IKeywords);
    });

    return {
      success: true,
      data: result,
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error,
    };
  }
};
