import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { NavigationHeader, Button } from 'core/components';
import { SelectProfile, SwitchControl } from 'core/components/@forms';

import { logout } from 'core/config/firebase';
import { useUser } from 'lib/hooks';

function SettingsContainer() {
  const navigate = useNavigate();
  const { userInfo, updateUserProfile, updateUserSearchOptions } = useUser();
  const [profile, setProfile] = useState<number>(0);

  useEffect(() => {
    userInfo && setProfile(userInfo.profile);
  }, [userInfo]);

  const handleSubmitProfile = useCallback(
    (value: number) => {
      if (userInfo) {
        updateUserProfile({
          ...userInfo,
          profile: value,
        });
      }
    },
    [userInfo],
  );

  const handleChangeOptions = useCallback(
    (checked: boolean) => {
      console.log('handleChangeOptions : ', checked);
      if (userInfo) {
        updateUserSearchOptions({
          ...userInfo,
          matchOptions: {
            sameSex: checked,
          },
        });
      }
    },
    [userInfo],
  );

  return (
    <main className="layout-contents-container page-settings">
      <NavigationHeader
        back
        title="설정"
        callback={() => {
          navigate(-1);
        }}
      />
      {userInfo && (
        <>
          <div className="contents-container" data-fit="true">
            <SelectProfile
              gender={userInfo.gender}
              value={userInfo.profile}
              onSubmit={handleSubmitProfile}
            />

            <dl className="list-settings">
              <div className="setting-item">
                <dt>
                  <label htmlFor="sameSex">동성 친구 찾기</label>
                </dt>
                <dd>
                  <SwitchControl
                    name="sameSex"
                    id="sameSex"
                    checked={userInfo.matchOptions.sameSex}
                    onChange={handleChangeOptions}
                  />
                </dd>
              </div>
              <div className="setting-item">
                <dt>
                  <label htmlFor="">latest updates</label>
                </dt>
                <dd>2023.09.05 14:10</dd>
              </div>
            </dl>
          </div>
          <Button intent="primary" onClick={logout}>
            로그아웃
          </Button>
          {/* <pre>{JSON.stringify(userInfo, null, 2)}</pre> */}
        </>
      )}
    </main>
  );
}

export default SettingsContainer;
