import React, { forwardRef, useEffect, useState } from 'react';

import { SelectAnswerProps } from './types';

function CSelectAnswer(props: SelectAnswerProps, ref: any) {
  const { options, value, onChange } = props;
  const [_value, _setValue] = useState<string>('');

  useEffect(() => {
    _setValue(value ? value : '');
  }, [value]);

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    _setValue(event.currentTarget.value);
    onChange && onChange(event.currentTarget.value);
  };

  return (
    <fieldset className="select-answer">
      <legend>답변 선택</legend>

      <div className="inner">
        {options.map((option) => {
          const { label, value } = option;

          return (
            <div key={`answer-${value}`} className="answer-item">
              <input
                type="radio"
                id={`answer-${value}`}
                name="answer"
                value={value}
                checked={value === _value}
                onChange={handleChangeValue}
              />
              <label
                htmlFor={`answer-${value}`}
                data-selected={_value ? value === _value : 'none'}
              >
                {label}
              </label>
            </div>
          );
        })}
      </div>
    </fieldset>
  );
}

const SelectAnswerRef = forwardRef(CSelectAnswer);
export const SelectAnswer = React.memo(SelectAnswerRef);
