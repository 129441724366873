import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';

import { apiExistUsers } from 'lib/api';
import { authService, SignInWithGoogle } from 'core/config/firebase';

import { Circles } from 'core/components';

import Slogan from './slogan';
import LegalMessage from './legal';

function SplashPage() {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(authService);
  const isPush = useRef(false);

  const afterLogin = async () => {
    const exist = await apiExistUsers(user?.email as string);
    if (exist) {
      navigate('/main');
    } else {
      toast('프로필 정보를 입력해주세요.');
      navigate('/auth/signup');
    }
  };

  useEffect(() => {
    if (!loading && user) {
      !isPush.current && afterLogin();
      isPush.current = true;
    }
  }, [user, loading]);

  return (
    <>
      <main className="layout-contents-container page-splash">
        <Slogan />

        <div className="actions">
          <Circles direction="right-bottom" />

          <button
            type="button"
            className="btn-oauth google"
            onClick={SignInWithGoogle}
          >
            구글 계정으로 로그인
          </button>
          {/* <button type="button" className="btn-oauth apple">
            애플 계정으로 로그인
          </button>
          <button
            type="button"
            className="btn-oauth facebook"
            onClick={SignInWithFacebook}
          >
            페이스북 계정 로그인
          </button> */}

          <LegalMessage />
        </div>
      </main>
    </>
  );
}

export default SplashPage;
