import { IRoute } from './types';

import { DefaultLayout } from 'core/layout';
import {
  GameContainer,
  GameOverContainer,
  MainPage,
  WaitingPage,
  ChatPage,
  SplashPage,
  SignUpPage,
  SettingsPage,
} from 'pages';

export const routes: IRoute[] = [
  {
    path: '/auth/signup',
    exact: true,
    component: SignUpPage,
    layout: DefaultLayout,
    name: 'SignUp',
    protected: true,
  },
  {
    path: '/settings',
    exact: true,
    component: SettingsPage,
    layout: DefaultLayout,
    name: 'Settings',
    protected: true,
  },
  {
    path: '/main',
    exact: true,
    component: MainPage,
    layout: DefaultLayout,
    name: 'Main',
    protected: true,
  },
  {
    path: '/waiting',
    exact: true,
    component: WaitingPage,
    layout: DefaultLayout,
    name: 'Waiting',
    protected: true,
  },
  {
    path: '/game-over',
    exact: true,
    component: GameOverContainer,
    layout: DefaultLayout,
    name: 'Game',
    protected: true,
  },
  {
    path: '/game',
    exact: true,
    component: GameContainer,
    layout: DefaultLayout,
    name: 'Game',
    protected: true,
  },
  {
    path: '/chat',
    exact: true,
    component: ChatPage,
    layout: DefaultLayout,
    name: 'Chat',
    protected: true,
  },
  {
    path: '/',
    exact: true,
    component: SplashPage,
    layout: DefaultLayout,
    name: 'Splash',
    protected: false,
  },
];
