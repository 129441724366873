import React, { useEffect, useState } from 'react';

import { useDocument, useCollectionData } from 'react-firebase-hooks/firestore';
import { query, collection, doc, orderBy } from 'firebase/firestore';

import { dbService } from 'core/config/firebase';
import { IChatUser, IChat, IMessage } from 'lib/model';
import {
  apiAddChat,
  apiUpdateChatState,
  apiUpdateChatUser,
  apiInActivate,
  apiPutMessage,
} from 'lib/api';

type useChatProps = {
  chatUid?: string;
};

export const useChat = (props: useChatProps = {}) => {
  const { chatUid = '' } = props;
  const [chatInfo, setChatInfo] = useState<IChat>();

  const messageCellection =
    chatUid !== ''
      ? query(
          collection(dbService, `chats/${chatUid}/messages`),
          orderBy('createdAt'),
        )
      : undefined;
  const messages = useCollectionData(messageCellection);
  const [value, loading, error] = useDocument(
    chatUid !== '' ? doc(dbService, 'chats', chatUid) : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  useEffect(() => {
    if (!loading && !error && value) {
      const _chat = value.data();
      setChatInfo(_chat as IChat);
    }
  }, [loading, error, value]);

  const createChat = async (users: string[]) => {
    const [host, guest] = users;
    const result = await apiAddChat(host, guest);
    if (result.success) {
      const chat: IChat = result.data as IChat;
      users.forEach((uid) => {
        apiUpdateChatState({
          uid: uid,
          chatUid: chat.uid,
        });
      });
    }
  };

  const putUserProfile = async (chatUid: string, user: IChatUser) => {
    const result = await apiUpdateChatUser(chatUid, user);
  };

  const putMessage = async (
    chatUid: string,
    userUid: string,
    message: string,
  ) => {
    const result = await apiPutMessage(chatUid, userUid, message);
  };

  const getProfileInfo = (uid: string) => {
    if (chatInfo?.host.uid === uid) {
      return chatInfo?.host;
    }
    if (chatInfo?.guest.uid === uid) {
      return chatInfo?.guest;
    }
    return null;
  };

  const putInActivate = async (chatUid: string) => {
    const result = await apiInActivate(chatUid);
  };

  return {
    isLoadedChat: !loading && !error,
    chatInfo,
    messages: messages[0] as IMessage[],
    createChat,
    putUserProfile,
    putMessage,
    putInActivate,
    getProfileInfo,
  };
};
