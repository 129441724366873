import React, { forwardRef } from 'react';
import ReactModal from 'react-modal';

import { AlertProps } from './types';

function CAlert(props: AlertProps, ref: any) {
  const { open = false, children, buttons, onClose } = props;

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <ReactModal
      isOpen={open}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      onRequestClose={handleClose}
      portalClassName={`ui-alert`}
      ref={ref}
    >
      <div className="alert-contents">{children}</div>

      {buttons && <div className="alert-footer">{buttons}</div>}
    </ReactModal>
  );
}

const AlertRef = forwardRef(CAlert);
export const Alert = React.memo(AlertRef);
