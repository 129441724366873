import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import _ from 'lodash';
import crypto from 'crypto-js';

import { PROFILES, getProfile } from 'core/config/constants';
import { IMessage } from 'lib/model';
import { useChat, useUser } from 'lib/hooks';
import { NavigationHeader, Button } from 'core/components';
import { WriteMessage } from 'core/components/@forms';

type ConvertMessageType = {
  convertedAt: Date;
  formatedByDate: string;
  me: boolean;
  start: boolean;
  end: boolean;
} & IMessage;

function ChatContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { id: string };

  !state && navigate('/main');

  const { userInfo, updateChatLastInfo } = useUser();
  const { chatInfo, messages, getProfileInfo } = useChat({
    chatUid: state.id,
  });
  const [conversation, setConversation] = useState<any>({});
  const containerEl = useRef<HTMLDivElement>(null);

  // 채팅 데이터 변환
  useEffect(() => {
    if (messages?.length > 0) {
      const _messages: any[] = [];
      let _prev: ConvertMessageType | null = null;

      messages.forEach((message, index) => {
        const { createdAt, user } = message;
        const nextMessage =
          index + 1 > messages.length ? null : messages[index + 1];

        const _date = createdAt as any;
        const _convertDate = new Date(
          _date.seconds * 1000 + _date.nanoseconds / 1000000,
        );

        let isEnd = true;
        if (nextMessage) {
          // console.log(
          //   'start : ',
          //   message.message,
          //   format(_prev?.createdAt, 'HH:mm'),
          // );
          const _NextDate = nextMessage.createdAt as any;
          const _convertNextDate = new Date(
            _NextDate.seconds * 1000 + _NextDate.nanoseconds / 1000000,
          );
          isEnd =
            nextMessage.user !== user ||
            format(_convertNextDate, 'HH:mm') !== format(_convertDate, 'HH:mm');
        }

        const isStart = _prev
          ? _prev?.user !== user ||
            format(_prev?.convertedAt, 'HH:mm') !==
              format(_convertDate, 'HH:mm')
          : true;

        const updated = {
          ...message,
          convertedAt: _convertDate,
          formatedByDate: format(_convertDate, 'yyyy-MM-dd'),
          me: user === userInfo?.uid,
          start: isStart,
          end: isEnd,
        };
        _prev = { ...updated };
        _messages.push(updated);
      });

      const grouped = _.chain(_messages).groupBy('formatedByDate').value();
      console.log(grouped);
      setConversation(grouped);
    }
  }, [messages, userInfo]);

  useEffect(() => {
    if (messages) {
      console.log(userInfo);
      if (userInfo?.uid) {
        updateChatLastInfo(state.id, userInfo.uid);
      }
      //
    }
  }, [messages, userInfo?.uid]);

  // 대화 내용에 따른 스크롤 이동
  useEffect(() => {
    if (containerEl) {
      containerEl?.current?.addEventListener('DOMNodeInserted', () => {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'auto',
        });
      });
    }

    return () => {
      if (containerEl) {
        containerEl?.current?.removeEventListener('DOMNodeInserted', () => {
          console.log('remove event');
        });
      }
    };
  }, []);

  return (
    <main className="layout-contents-container page-chat">
      <NavigationHeader
        close
        callback={() => {
          console.log('close callback');
          navigate(-1);
        }}
      />
      <div className="chat-container" ref={containerEl}>
        {Object.keys(conversation).map((key, index) => {
          const messages = [...conversation[key]];
          return (
            <>
              <time dateTime={key} className="date">
                {key}
              </time>
              <dl className="list-messages">
                {messages.map((item, index) => {
                  const { user, message, convertedAt, me, start, end } = item;
                  const _profile = getProfileInfo(user);
                  const bytes = crypto.AES.decrypt(
                    message,
                    `${process.env.MESSAGE_SECRET_KEY}`,
                  );

                  const decrypt = bytes.toString(crypto.enc.Utf8);

                  return (
                    <div
                      key={index}
                      className="message-item"
                      data-me={me}
                      data-start={start}
                      data-end={end}
                    >
                      <dt>
                        {_profile && (
                          <>
                            <span className="profile">
                              <img
                                src={getProfile(
                                  _profile?.gender,
                                  _profile?.profile,
                                )}
                                alt={_profile?.nickname}
                              />
                            </span>
                            <span className="nickname">
                              {_profile?.nickname}
                            </span>
                          </>
                        )}
                      </dt>
                      <dd>
                        <p className="message">
                          {decrypt}
                          <time dateTime={convertedAt} className="date">
                            {format(convertedAt, 'HH:mm')}
                          </time>
                        </p>
                      </dd>
                    </div>
                  );
                })}
              </dl>
            </>
          );
        })}

        {chatInfo?.uid && userInfo?.uid && (
          <WriteMessage chatUid={chatInfo?.uid} userUid={userInfo?.uid} />
        )}
      </div>
    </main>
  );
}

export default ChatContainer;
