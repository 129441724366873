import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { useChat } from 'lib/hooks';
import { WriteMessageProps } from './types';
import { toast } from 'react-toastify';

function CWriteMessage(
  props: WriteMessageProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { putMessage } = useChat();

  const { chatUid, userUid } = props;

  const [value, setValue] = useState('');

  useEffect(() => {
    // console.log(props);
  }, []);

  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  };

  const handleClickSend = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (value.length > 0) {
      putMessage(chatUid as string, userUid as string, value);
      setValue('');
    }
  };

  return (
    <div className="write-container" ref={ref}>
      <form>
        <fieldset className="form-write">
          <legend>대화 입력</legend>

          <div className="control-item">
            <input
              type="text"
              name="message"
              id="message"
              value={value}
              onChange={handleChangeMessage}
            />
            <button
              type="submit"
              className="btn-send"
              onClick={handleClickSend}
              disabled={value.length === 0}
            >
              전송
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

const WriteMessageRef = forwardRef(CWriteMessage);
export const WriteMessage = React.memo(WriteMessageRef);
