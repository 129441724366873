import React from 'react';
import ReactModal from 'react-modal';

import { NavigationHeader, Button } from 'core/components';
import { useModalState } from 'core/hooks';

function LegalMessage() {
  const modalTOC = useModalState({});
  const modalPrivacy = useModalState({});

  return (
    <>
      <p className="legal-message">
        가입하면{' '}
        <button type="button" title="이용약관 팝업" onClick={modalTOC.open}>
          &lsquo;이용약관&rsquo;
        </button>{' '}
        및
        <button
          type="button"
          title="개인정보 처리방침 팝업"
          onClick={modalPrivacy.open}
        >
          &lsquo;개인정보 처리방침&rsquo;
        </button>
        에 동의하게 됩니다
      </p>

      {/* Modal Page - 이용약관 */}
      <ReactModal isOpen={modalTOC.isShowing} closeTimeoutMS={300}>
        <div className="layout-contents-container page-modal-legal">
          <NavigationHeader title="서비스 이용약관" />

          <div className="modal-contents">
            서비스 이용약관
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            서비스 이용약관
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            서비스 이용약관
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            서비스 이용약관
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            서비스 이용약관
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            서비스 이용약관
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            서비스 이용약관
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            서비스 이용약관
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            서비스 이용약관
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            서비스 이용약관
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>

          <div className="navigation-submit-actions">
            <Button onClick={modalTOC.close}>닫기</Button>
          </div>
        </div>
      </ReactModal>

      {/* Modal Page - 개인정보 처리방침 */}
      <ReactModal isOpen={modalPrivacy.isShowing} closeTimeoutMS={300}>
        <div className="layout-contents-container page-modal-legal">
          <NavigationHeader title="개인정보 처리방침" />

          <div className="modal-contents">
            개인정보 처리방침
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            개인정보 처리방침
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            개인정보 처리방침
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            개인정보 처리방침
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            개인정보 처리방침
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            개인정보 처리방침
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            개인정보 처리방침
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            개인정보 처리방침
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            개인정보 처리방침
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            개인정보 처리방침
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>

          <div className="navigation-submit-actions">
            <Button onClick={modalPrivacy.close}>닫기</Button>
          </div>
        </div>
      </ReactModal>
    </>
  );
}

export default LegalMessage;
