import React, { forwardRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGame, useUser, useWaitings } from 'lib/hooks';

import { Circles } from 'core/components';

function WaitingContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  !state && navigate('/main');

  const { createGame } = useGame();
  const { userInfo, updateUser, resetUserGameState, setRemainCoin } = useUser();
  const { roomUid, usersInRoom, addInRoom, matchingUser, removeUserInRoom } =
    useWaitings();

  useEffect(() => {
    if (roomUid && state.userUid && userInfo?.game.state) {
      console.log(' ');
      console.log(`+++++ useEffect : ${userInfo?.game.state} +++++`);
      console.log(' ');
      const userUid = state.userUid;
      const exists = usersInRoom.includes(userUid);

      if (userInfo.game.state === 'in-game') {
        console.log('!!!!! game으로 이동 !!!!! : ', userInfo.coin.remain);
        setRemainCoin({
          lastUpdated: Date.now(),
          remain: userInfo.coin.remain - 1,
        });

        navigate('/game', {
          state: {
            id: userInfo.game.currentUid,
          },
        });
      }

      console.log('matching start');
      matchingUser(roomUid, userUid).then((result) => {
        console.log('===== matching done : ', result);

        if (result) {
          if (userInfo.game.state !== 'in-game') {
            console.log('matchingUser 성공 : 게임생성');
            createGame([userUid, result]);
          }
        } else {
          if (!exists) {
            console.log('add waiting room');
            addInRoom(roomUid, userUid).then((result) => {
              console.log('addInRoom : ', result);
              if (result) {
                updateUser({
                  ...userInfo,
                  game: {
                    state: 'in-waiting',
                    currentUid: '',
                  },
                });
              }
            });
          }
        }
      });

      console.log('===== useeffect done =====');
      console.log(' ');
      console.log(' ');
    }
  }, [userInfo?.game.state]);

  const handleClickClose = async () => {
    if (roomUid && state.userUid) {
      console.log('===== 삭제 =====');
      removeUserInRoom(roomUid, state.userUid);
      resetUserGameState(state.userUid);
    }
    navigate('/main');
  };

  return (
    <main className="layout-contents-container page-waiting">
      <div className="contents-container">
        <aside className="match-loading" data-open={true}>
          <h2 className="mask-loader">1inmil</h2>
          <p className="slogan">반복된 우연은 운명.</p>

          <button className="btn-close" onClick={handleClickClose}>
            닫기
          </button>

          <Circles direction="left-top" />
        </aside>
      </div>
    </main>
  );
}

export default WaitingContainer;
