import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useForm, SubmitHandler } from 'react-hook-form';
import { useAuthState } from 'react-firebase-hooks/auth';
import { authService, SignInWithGoogle } from 'core/config/firebase';
import { REGION } from 'core/config/constants';

import { apiExistNickname, apiAddUser } from 'lib/api';

import { Button, NavigationHeader } from 'core/components';
import { SelectControl } from 'core/components/@forms';

type IFormInputs = {
  nickname: string;
  gender: string;
  age: number;
  indian: string;
  region: string;
};

function SignUpContainer() {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(authService);
  const { formState, register, trigger, handleSubmit, setValue, watch } =
    useForm<IFormInputs>({
      mode: 'onBlur',
    });
  const { isValid, errors } = formState;

  const genderValue = watch('gender');
  const indianValue = watch('indian');
  const regionValue = watch('region');

  const genderRegister = register('gender', {
    required: {
      value: true,
      message: '성별을 선택해주세요',
    },
  });
  const indianRegister = register('indian', {
    required: {
      value: true,
      message: '인디언 이름을 선택해주세요',
    },
  });
  const regionRegister = register('region', {
    required: {
      value: true,
      message: '지역을 선택해주세요',
    },
  });

  const [age, setAge] = useState<number>(0);
  const handleChangeAge = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      console.log('handleChangeAge : ', event.currentTarget.value);
      const _age = +event.currentTarget.value;
      if (_age >= 18) {
        setAge(_age);
      }
    },
    [],
  );

  const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
    const result = await apiAddUser(data, user);
    if (result.success) {
      toast('프로필 등록이 완료되었습니다.');
      navigate('/main');
    }
  };

  const checkNickname = async (nickname: string) => {
    const result = await apiExistNickname(nickname);
    return !result;
  };

  return (
    <main className="layout-contents-container page-signup">
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <NavigationHeader title="프로필" />

        <div className="contents-container">
          <fieldset className="forms-signup">
            <legend>회원가입 입력 폼</legend>

            <div className="form-item">
              <label htmlFor="nickname" className="form-label">
                닉네임
              </label>
              <div className="form-content">
                <input
                  type="text"
                  id="nickname"
                  className="ui-control ui-textfield"
                  placeholder=""
                  {...register('nickname', {
                    required: {
                      value: true,
                      message: '닉네임을 입력해주세요',
                    },
                    minLength: {
                      value: 3,
                      message: '3자 이상 8자 이하로 입력해주세요.',
                    },
                    maxLength: {
                      value: 8,
                      message: '3자 이상 8자 이하로 입력해주세요.',
                    },
                    validate: checkNickname,
                  })}
                />
                {errors.nickname && (
                  <p className="invalid-message">
                    {errors.nickname.type === 'validate'
                      ? '사용할 수 없는 닉네임입니다.'
                      : `${errors.nickname.message}`}
                  </p>
                )}
              </div>
            </div>

            <div className="form-item">
              <label htmlFor="gender" className="form-label">
                성별
              </label>
              <div className="form-content">
                <SelectControl
                  placeholder="선택"
                  value={[genderValue]}
                  onBlur={() => {
                    trigger('gender');
                  }}
                  onChange={(value) => {
                    setValue('gender', value.toString());
                    trigger('gender');
                  }}
                  items={[
                    { label: '남자', value: 'male' },
                    { label: '여자', value: 'female' },
                  ]}
                />
                {errors.gender && (
                  <p className="invalid-message">{errors.gender.message}</p>
                )}
              </div>
            </div>

            <div className="form-item">
              <label htmlFor="age" className="form-label">
                나이
              </label>
              <div className="form-content">
                <input
                  type="number"
                  id="age"
                  className="ui-control ui-textfield"
                  placeholder="만 18세 이상"
                  {...register('age', {
                    required: {
                      value: true,
                      message: '나이를 입력해주세요',
                    },
                    min: {
                      value: 18,
                      message: '만 18세 이상부터 1inmil을 이용할 수 있습니다.',
                    },
                    max: {
                      value: 99,
                      message: '만 18세 이상부터 1inmil을 이용할 수 있습니다.',
                    },
                  })}
                  onChange={handleChangeAge}
                />
                {errors.age && (
                  <p className="invalid-message">{errors.age.message}</p>
                )}
              </div>
            </div>

            <div className="form-item">
              <label htmlFor="indian" className="form-label">
                인디언 이름
              </label>
              <div className="form-content">
                <SelectControl
                  name="indian"
                  placeholder="선택"
                  age={age}
                  disabled={age < 18}
                  value={[indianValue]}
                  onBlur={() => {
                    trigger('indian');
                  }}
                  onChange={(value) => {
                    setValue('indian', value.toString());
                    trigger('indian');
                  }}
                  items={[
                    { label: '1월', value: '0', year: '' },
                    { label: '2월', value: '1', year: '' },
                    { label: '3월', value: '2', year: '' },
                    { label: '4월', value: '3', year: '' },
                    { label: '5월', value: '4', year: '' },
                    { label: '6월', value: '5', year: '' },
                    { label: '7월', value: '6', year: '' },
                    { label: '8월', value: '7', year: '' },
                    { label: '9월', value: '8', year: '' },
                    { label: '10월', value: '9', year: '' },
                    { label: '11월', value: '10', year: '' },
                    { label: '12월', value: '11', year: '' },
                  ]}
                />
                {errors.indian && (
                  <p className="invalid-message">{errors.indian.message}</p>
                )}
              </div>
            </div>

            <div className="form-item">
              <label htmlFor="region" className="form-label">
                지역
              </label>
              <div className="form-content">
                <SelectControl
                  placeholder="선택"
                  value={[regionValue]}
                  onBlur={() => {
                    trigger('region');
                  }}
                  onChange={(value) => {
                    setValue('region', value.toString());
                    trigger('region');
                  }}
                  items={REGION}
                />
                {errors.region && (
                  <p className="invalid-message">{errors.region.message}</p>
                )}
              </div>
            </div>
          </fieldset>

          {/* 휴대폰 인증 */}
        </div>

        <div className="navigation-submit-actions">
          <Button type="submit" intent="confirm" disabled={!isValid}>
            프로필 등록
          </Button>
        </div>
      </form>
    </main>
  );
}
export default SignUpContainer;
