/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDocument } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';

import { dbService } from 'core/config/firebase';
import { IWaitingRoom, IUser, IGame } from 'lib/model';
import {
  apiAddGame,
  apiUpdateGameState,
  apiPutAnswer,
  apiConfirmAnswer,
} from 'lib/api';

type useGameProps = {
  gameUid?: string;
};

export const useGame = (props: useGameProps = {}) => {
  const { gameUid = '' } = props;
  const [gameInfo, setGameInfo] = useState<IGame>();

  const [value, loading, error] = useDocument(
    gameUid !== '' ? doc(dbService, 'games', gameUid) : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  useEffect(() => {
    if (!loading && !error && value) {
      const _game = value.data();
      setGameInfo(_game as IGame);
    }
  }, [loading, error, value]);

  const createGame = async (users: string[]) => {
    console.log('[createGame] :', users);
    const result = await apiAddGame(users);
    if (result.success) {
      const game: IGame = result.data as IGame;
      users.forEach((uid) => {
        apiUpdateGameState({
          uid: uid,
          state: 'in-game',
          gameUid: game.uid,
        });
      });
    }
  };

  const putAnswer = async (
    game: string,
    user: string,
    step: number,
    answer: string,
  ) => {
    const result = await apiPutAnswer(game, user, step, answer);
  };

  const confirmAnswer = async (game: string, user: string, step: number) => {
    return await apiConfirmAnswer(game, user, step);
  };

  const getOpponentUid = (uid: string) => {
    if (gameInfo) {
      console.log(
        'opponent uid : ',
        gameInfo.host.uid === uid ? gameInfo.guest.uid : gameInfo.host.uid,
      );
      return gameInfo.host.uid === uid ? gameInfo.guest.uid : gameInfo.host.uid;
    } else {
      return '';
    }
  };

  return {
    isLoaded: !loading && !error && value,
    gameInfo,
    createGame,
    putAnswer,
    confirmAnswer,
    getOpponentUid,
  };
};
