import React, { forwardRef, useEffect, useState } from 'react';

import { SwitchControlProps } from './types';

function CSwitchControl(
  props: SwitchControlProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    name,
    id,
    checked = false,
    className,
    onChange,
    ...otherProps
  } = props;
  const [_value, _setValue] = useState<boolean>(checked);

  useEffect(() => {
    _setValue(checked);
  }, [checked]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.currentTarget.checked);
    _setValue(event.currentTarget.checked);
  };

  return (
    <span className={`ui-control ui-switch-control ${className}`}>
      <input
        type="checkbox"
        name={name}
        id={id}
        checked={_value}
        onChange={handleChange}
        {...otherProps}
        ref={ref}
      />
      <label htmlFor={id} className="switch">
        <span className="thumb"></span>
      </label>
    </span>
  );
}

const SwitchControlRef = forwardRef(CSwitchControl);
export const SwitchControl = React.memo(SwitchControlRef);
SwitchControl.displayName = 'SwitchControl';
