import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay } from 'swiper';

const slogans = [
  '백만분의 1은 인연이야.',
  '반복된 우연은 운명.',
  '취향이 같은 진짜인연.',
];

function Slogan() {
  return (
    <header className="header">
      <h1 className="title">1inmil</h1>
      <Swiper
        modules={[EffectFade, Autoplay]}
        effect="fade"
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        speed={1000}
        loop
        className="swiper-slogal"
      >
        {slogans.map((item, index) => {
          return (
            <SwiperSlide key={`slogan-${index}`} className="slogan-item">
              {item}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </header>
  );
}

export default Slogan;
