import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import { NavigationHeaderProps } from './types';
import { RemainCounter } from 'core/components';

function CNavigationHeader(
  props: NavigationHeaderProps,
  ref: React.ForwardedRef<HTMLElement>,
) {
  const { title, back, close, setting, counter, step, callback } = props;

  return (
    <header className="navigation-header" ref={ref}>
      <div className="actions">
        {setting && (
          <Link to="/settings" className="btn-navigation" data-icon="setting">
            설정
          </Link>
        )}
        {back && (
          <button
            type="button"
            className="btn-navigation"
            data-icon="back"
            onClick={callback}
          >
            뒤로
          </button>
        )}
        {close && (
          <button
            type="button"
            className="btn-navigation"
            data-icon="close"
            onClick={callback}
          >
            닫기
          </button>
        )}
      </div>

      {title && <h1 className="navigation-title">{title}</h1>}

      {counter && (
        <div className="counter-actions">
          <RemainCounter />
        </div>
      )}
      {step !== undefined && <span className="step-actions">{step}/20</span>}
    </header>
  );
}

const forwardNavigationHeader = forwardRef(CNavigationHeader);
export const NavigationHeader = React.memo(forwardNavigationHeader);
